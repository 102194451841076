a:link { color:black;text-decoration: none; }
a:visited {color:black; text-decoration: none; }
a:hover { color:#fcaf3e;text-decoration:none; }
a:active { color:black;text-decoration:none; }


button:hover{background-color:#fcaf3e;}
h3:hover{color:#fcaf3e}
h1:hover{color:#fcaf3e}
  
button {
  color:white;
  background-color:#013387;
  border-radius:15px;
  

}

h3 {
  color:white;
}

p {
  color:white;
}

.Website {
  text-align: left;
  color: white;
  border: "";
  
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
